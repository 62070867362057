import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';

function PageNotFound() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                
                {/* 404 content */}
                <h1 className="h1 mb-4" data-aos="fade-up" data-aos-delay="200">The portal is now closed.</h1>
                {/*<p className="text-lg text-gray-400" data-aos="fade-up" data-aos-delay="400">Head to our <Link to="/hugo" className="text-green-600 hover:text-gray-200 transition duration-150 ease-in-out">product overview</Link> for more information about HUGO.</p>*/}
              </div>
            </div>
          </div>
        </section>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default PageNotFound;
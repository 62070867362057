import React from 'react';

function HeroHome() {
	return (
		<section>
			<div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
				{/* Hero content */}
				<div className="relative pt-32 pb-0 md:pt-40 md:pb-0">
					{/* Section header */}
					<div className="max-w-3xl mx-auto text-left pb-8 md:pb-16">
						<h1 className="h2 mb-4" data-aos="fade-up">
							Operational excellence for funds lawyers and investment managers.
						</h1>
						
					</div>
				</div>
			</div>
		</section>
	);
}

export default HeroHome;

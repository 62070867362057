import React from 'react';
import { Link } from 'react-router-dom';

function Header() {


  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Desktop navigation */}
          <nav className="flex flex-grow">
            {/* Desktop menu links */}
     
            <ul className="flex flex-grow justify-start flex-wrap items-center">
                  {/*
              <li>
                <Link to="/" className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  Start
                </Link>
              </li>
             */}
              
            </ul>
          </nav>

          

        </div>
      </div>
    </header>
  );
}

export default Header;
